import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { RootDataContext } from "../../../index"
import DataManager from "../../../../../manager/data"
import APIManager from "../../../../../manager/api"

const SOURCE_NAME = "prediction_douro_yosoku"
const LAYER_NAME = "prediction_douro_yosoku_layer"
const PredictDouroLayer = (props) => {
  const { state } = useContext(RootDataContext)
  const map = useMemo(() => props.map, [props.map])
  const canvas = useMemo(() => map?.getCanvasContainer(), [map])
  const [tiles, setTiles] = useState()
  const [initialized, setInitialized] = useState(false)

  const query = useMemo(() => {
    if (!state.predictionMode) {
      return null
    }

    return DataManager.selectQueryDourojikoyosoku(
      state.predictionMode,
      state.schoolArea?.name
    )
  }, [state.predictionMode, state.schoolArea])

  useEffect(() => {
    if (!map || !query) {
      return
    }

    APIManager.getVectorTile(query)
      .then((res) => {
        console.log("Douro Tile", res)
        setTiles(res)
      })
      .catch((e) => {
        console.log("Get Vector error", e)
      })
  }, [map, query])

  useEffect(() => {
    if (!map || !canvas || !tiles) {
      return
    }

    let source = map.getSource(SOURCE_NAME)
    if (source) {
      source.setTiles(tiles)
      return
    }

    map.addSource(SOURCE_NAME, {
      type: "vector",
      tiles: tiles,
    })
    map.addLayer({
      id: LAYER_NAME + "_line",
      type: "line",
      source: SOURCE_NAME,
      "source-layer": "layer0",
      minzoom: 10,
      maxzoom: 22,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": [
          "case",
          ["<", ["get", "risk"], 0.1],
          "#1a9641",
          ["all", [">=", ["get", "risk"], 0.1], ["<", ["get", "risk"], 0.25]],
          "#a6d96a", // allてのはandってこと
          ["all", [">=", ["get", "risk"], 0.25], ["<", ["get", "risk"], 0.5]],
          "#ffffbf",
          ["all", [">=", ["get", "risk"], 0.5], ["<", ["get", "risk"], 0.75]],
          "#fdae61",
          "#d7191c",
        ],
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          1,
          15,
          3.0,
          18,
          4.0,
        ],
      },
    })

    map
      .on("click", `${LAYER_NAME}_line`, (e) => {
        props.onClick && props.onClick(e)
      })
      .on("mouseenter", `${LAYER_NAME}_line`, () => {
        canvas.style.cursor = "pointer"
      })
      .on("mouseleave", `${LAYER_NAME}_line`, () => {
        canvas.style.cursor = "grab"
      })
    setInitialized(true)
    props.onLoad && props.onLoad()
  }, [map, canvas, tiles])

  useEffect(() => {
    if (!map || !initialized) { return }

    map.setLayoutProperty(`${LAYER_NAME}_line`, 'visibility', state.viewData.includes("道路事故予測") ? "visible": "none")

  }, [map, initialized, state.viewData])

  return null
}

export const Order = (map) => {
  map.moveLayer(`${LAYER_NAME}_line`)
}

PredictDouroLayer.propTypes = {
  map: PropTypes.any,
  onLoad: PropTypes.func,
  onClick: PropTypes.func,
}

export default PredictDouroLayer
