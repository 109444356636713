import PropTypes from "prop-types"
import { Box } from "@mui/material"
import AggregateCardView from "./aggregateCard"
import React, { useContext } from "react"
import PredictKousatenCard from "./predictCard/kousaten"
import PredictDouroCard from "./predictCard/douro"
import PredictAverageCard from "./predictCard/average"
import { RootDataContext } from "../../index"

const styles = {
  card: {
    margin: "5px 0",
  },
}

const RootRightPanelView = (props) => {
  const { state } = useContext(RootDataContext)

  return (
    <Box
      sx={{
        color: "white",
        display: "flex",
        flexDirection: "column",
        ...props.sx,
      }}
    >
      {/* 右ペイン */}
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box style={{ flexGrow: 1 }} />
      </Box>
      {state.viewData.includes("事故実績") && <AggregateCardView />}
      <Box>
        {(state.viewData.includes("道路事故予測") ||
          state.viewData.includes("交差点事故予測")) && (
          <PredictAverageCard sx={styles.card} />
        )}
        {state.viewData.includes("道路事故予測") && (
          <PredictDouroCard sx={styles.card} />
        )}
        {state.viewData.includes("交差点事故予測") && (
          <PredictKousatenCard sx={styles.card} />
        )}
      </Box>
    </Box>
  )
}

RootRightPanelView.propTypes = {
  sx: PropTypes.object,
}

export default RootRightPanelView
