import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { RootDataContext } from "../../../index"
import APIManager from "../../../../../manager/api"
import PieChart from "./pieChart"

const styles = {
  root: {
    backgroundColor: "#f3f3f3",
    padding: "8px",
    marginBottom: "5px",
  },
  pieBox: {
    margin: "8px",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
  chart: {
    width: "calc(50% - 4px)", // なぜかコレをしないとChartJSが100%で出力される
  },
}

const fillColors = [
  "#cc9",
  "#9cc",
  "#c9c",
  "#99c",
  "#9c9",
  "#c99",
  "#c63",
  "#36c",
  "#93c",
  "#c66",
  "#6c6",
  "#66c",
  "#933",
  "#393",
  "#993",
]

const borderColors = [
  "#a6a67c",
  "#739a9a",
  "#936e93",
  "#6b6b8f",
  "#709670",
  "#936e6e",
  "#964b25",
  "#264c98",
  "#67228a",
  "#9b4e4e",
  "#4e9b4e",
  "#4b4b98",
  "#6b2323",
  "#246c24",
  "#6b6b23",
]

const RootAggregateCardView = (props) => {
  const { state } = useContext(RootDataContext)
  const [ageChartData, setAgeChartData] = useState(null)
  const [injuryChartData, setInjuryChartData] = useState(null)
  const [totalCount, setTotalCount] = useState("")

  useEffect(() => {
    if (!state.mapViewBounds) {
      // TODO: 消す
      return
    }

    APIManager.getTotalCount(state.rangeType, state.mapViewBounds, {
      timeslot: state.timeslotFilter,
      age: state.ageFilter,
      type: state.typeFilter,
      injury: state.injuryFilter,
      weather: state.weatherFilter,
      school_area: state.schoolArea?.name,
    }).then((row) => {
      setTotalCount(parseInt(row["count"]).toLocaleString())
    })

    APIManager.getInjuryPieChartData(
      {
        timeslot: state.timeslotFilter,
        age: state.ageFilter,
        type: state.typeFilter,
        injury: state.injuryFilter,
        weather: state.weatherFilter,
        school_area: state.schoolArea?.name,
      },
      state.rangeType,
      state.mapViewBounds
    ).then((rows) => {
      setInjuryChartData({
        labels: rows.map((v) => v.label),
        datasets: [
          {
            data: rows.map((v) => v.value),
            backgroundColor: rows.map((_, i) => fillColors[i]),
            borderColor: rows.map((_, i) => borderColors[i]),
            borderWidth: 1,
          },
        ],
      })
    })

    APIManager.getAgePieChartData(
      {
        timeslot: state.timeslotFilter,
        age: state.ageFilter,
        type: state.typeFilter,
        injury: state.injuryFilter,
        weather: state.weatherFilter,
        school_area: state.schoolArea?.name,
      },
      state.rangeType,
      state.mapViewBounds
    ).then((rows) => {
      setAgeChartData({
        labels: rows.map((v) => v.label),
        datasets: [
          {
            data: rows.map((v) => v.value),
            backgroundColor: rows.map((_, i) => fillColors[i]),
            borderColor: rows.map((_, i) => borderColors[i]),
            borderWidth: 1,
          },
        ],
      })
    })
  }, [
    state.rangeType,
    state.mapViewBounds,
    state.timeslotFilter,
    state.ageFilter,
    state.typeFilter,
    state.injuryFilter,
    state.weatherFilter,
    state.schoolArea,
  ])

  return (
    <Box sx={{...styles.root, ...props.sx}}>
      <Box
        sx={{
          margin: "1px",
          backgroundColor: "white",
          borderRadius: "6px",
          padding: "4px",
        }}
      >
        {totalCount.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              marginBottom: "4px",
            }}
          >
            <Typography color="#3c6960" variant="subtitle1">
              事故発生件数：
            </Typography>
            <Typography color="#3c6960" variant="h4">
              {totalCount}
            </Typography>
            <Typography color="#3c6960" variant="subtitle1">
              件
            </Typography>
          </Box>
        )}
        <Box sx={styles.pieBox}>
          {ageChartData && (
            <PieChart sx={styles.chart} data={ageChartData} />
          )}
          {injuryChartData && (
            <PieChart sx={styles.chart} data={injuryChartData} />
          )}
        </Box>
      </Box>
    </Box>
  )
}

RootAggregateCardView.propTypes = {
  sx: PropTypes.object,
  type: PropTypes.string,
}

export default RootAggregateCardView
