import React, { useContext, useState } from "react"
import LeftPanelView from "./leftPanel"
import { Box } from "@mui/material"
import HeaderView from "./header"
import MapView from "./map"
import RightPanelView from "./rightPanel"
import PropTypes from "prop-types"
import { RootDataContext } from "../index"

const styles = {
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  rightPanel: {
    width: "300px",
    height: "100%",
    margin: "0 8px",
  },
}

const RootMapContentsView = (props) => {
  const { state } = useContext(RootDataContext)

  return (
    <Box sx={{...styles.root, ...props.styles}}>
      <HeaderView />
      <Box sx={styles.main}>
        <LeftPanelView />
        <MapView />
        {state.visibleRightPane && (
          <RightPanelView sx={styles.rightPanel} />
        )}
      </Box>
    </Box>
  )
}

RootMapContentsView.propTypes = {
  styles: PropTypes.object,
}

export default RootMapContentsView
