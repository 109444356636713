export const ActionType = {
  setUser: "SET_USER",
  setUserUId: "SET_USER_UID",
  setViewType: "SET_VIEW_TYPE",
  setAccidentFilter: "SET_ACCIDENT_FILTER",
  setViewDataType: "SET_VIEW_DATA_TYPE",
  setTimeslotFilter: "SET_TIMESLOT_FILTER",
  setAgeFilter: "SET_AGE_FILTER",
  setTypeFilter: "SET_TARGET_FILTER",
  setInjuryFilter: "SET_INJURY_FILTER",
  setWeatherFilter: "SET_WEATHER_FILTER",
  setViewData: "SET_VIEW_DATA",
  setPredictionMode: "SET_PREDICTION_MODE",
  setMapViewBounds: "SET_MAP_VIEW_BOUNDS",
  setMapCenter: "SET_MAP_CENTER",
  setVisibleRightPane: "SET_VISIBLE_RIGHT_PANE",
  setClickActionData: "SET_CLICK_ACTION_DATA",
  setRangeType: "SET_RANGE_TYPE",
  setPinLocation: "SET_PIN_LOCATION",
  setLoadingCount: "SET_LOADING_COUNT",
  setCommentEditMode: "SET_COMMENT_EDIT_MODE",
  setCommentEditData: "SET_COMMENT_EDIT_DATA",

  setStateDialogOpen: "SET_STATE_DIALOG_OPEN",
  setStateDialogTitle: "SET_STATE_DIALOG_TITLE",
  setStateDialogContent: "SET_STATE_DIALOG_CONTENT",
  setStateDialogActions: "SET_STATE_DIALOG_ACTIONS",
  setStateDialogOnClose: "SET_STATE_DIALOG_ON_CLOSE",

  setSchoolArea: "SET_SCHOOL_AREA",
}

const RootViewDataReducer = (state, action) => {
  switch (action.type) {
    case ActionType.setUser:
      return { ...state, user: action.value }
    case ActionType.setUserUId:
      return { ...state, userUid: action.value }
    case ActionType.setViewType:
      return { ...state, viewType: action.value }
    case ActionType.setAccidentFilter:
      return { ...state, filterData: action.value }
    case ActionType.setViewDataType:
      return { ...state, viewType: action.value }
    case ActionType.setTimeslotFilter:
      return { ...state, timeslotFilter: action.value }
    case ActionType.setAgeFilter:
      return { ...state, ageFilter: action.value }
    case ActionType.setTypeFilter:
      return { ...state, typeFilter: action.value }
    case ActionType.setInjuryFilter:
      return { ...state, injuryFilter: action.value }
    case ActionType.setWeatherFilter:
      return { ...state, weatherFilter: action.value }
    case ActionType.setViewData:
      return { ...state, viewData: action.value }
    case ActionType.setPredictionMode:
      return { ...state, predictionMode: action.value }
    case ActionType.setMapViewBounds:
      return { ...state, mapViewBounds: action.value }
    case ActionType.setMapCenter:
      return { ...state, mapCenter: action.value }
    case ActionType.setVisibleRightPane:
      return { ...state, visibleRightPane: action.value }
    case ActionType.setClickActionData:
      return { ...state, clickActionData: action.value }
    case ActionType.setRangeType:
      return { ...state, rangeType: action.value }
    case ActionType.setPinLocation:
      return { ...state, pinLocation: action.value }
    case ActionType.setLoadingCount:
      return { ...state, loadingCount: action.value }
    case ActionType.setCommentEditMode:
      return { ...state, isCommentEditMode: action.value }
    case ActionType.setCommentEditData:
      return { ...state, commentEditData: action.value }
    case ActionType.setStateDialogOpen:
      return { ...state, openStateDialog: action.value }
    case ActionType.setStateDialogTitle:
      return { ...state, stateDialogTitle: action.value }
    case ActionType.setStateDialogContent:
      return { ...state, stateDialogContent: action.value }
    case ActionType.setStateDialogActions:
      return { ...state, stateDialogActions: action.value }
    case ActionType.setStateDialogOnClose:
      return { ...state, stateDialogOnClose: action.value }
    case ActionType.setSchoolArea:
      return { ...state, schoolArea: action.value }
    default:
      break
  }

  return state
}

export default RootViewDataReducer
