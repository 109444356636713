import axios from "axios"
axios.defaults.headers.post["Content-Type"] = "application/json"

const predictionModeValues = [
  { label: "グローバル", value: "global" },
  { label: "グローバル：晴", value: "sunny" },
  { label: "グローバル：雨", value: "rainy" },
  { label: "朝方", value: "mor" },
  { label: "昼間", value: "day" },
  { label: "夕方", value: "eve" },
  { label: "夜間", value: "nig" },
  { label: "未成年", value: "stud" },
  { label: "成人", value: "adlt" },
  { label: "高齢者", value: "elde" },
]

export default new (class {
  accessTokenFunc = null

  lastToken = null

  base64encode = (param) => {
    return encodeURIComponent(JSON.stringify(param))
  }

  accessToken = async () => {
    if (!this.accessTokenFunc) {
      return null
    }
    let token = await this.accessTokenFunc({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:current_user",
    })
    this.lastToken = token
    return token
  }

  headers = (token, otherHeaders) => {
    return {
      AuthorizationToken: `Bearer ${token}`,
      ...otherHeaders,
    }
  }

  getQueryString = (params) =>
    Object.keys(params)
      .filter((k) => params[k] !== null && params[k] !== undefined)
      .map((k) => {
        if (k === null || k === undefined) {
          return null
        }
        switch (typeof params[k]) {
          case "object":
            return `${k}=${encodeURI(JSON.stringify(params[k]))}`
          default:
            return `${k}=${encodeURI(params[k])}`
        }
      })
      .join("&")

  resetPassword = (password, rePassword) => {
    return new Promise((resolve, reject) => {
      this.accessToken()
        .then((token) => {
          axios
            .post(
              `${process.env.REACT_APP_AUTH_API_BASEURL}/new_password`,
              {
                new_password: password,
                re_new_password: rePassword,
              },
              {
                headers: this.headers(token),
              }
            )
            .then((res) => {
              //              console.log(res.data)
              //              resolve(res.data)
              window.location.reload()
            })
            .catch((e) => {
              console.log(e)
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  policyAgreed = (version) => {
    return new Promise((resolve, reject) => {
      this.accessToken()
        .then((token) => {
          axios
            .post(
              `${process.env.REACT_APP_AUTH_API_BASEURL}/policy_agreed`,
              {
                version,
              },
              {
                headers: this.headers(token),
              }
            )
            .then((res) => {
              resolve(res.data)
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  userInfo = () => {
    return new Promise((resolve, reject) => {
      this.accessToken()
        .then((token) => {
          axios
            .get(`${process.env.REACT_APP_AUTH_API_BASEURL}/info`, {
              headers: this.headers(token),
            })
            .then((res) => {
              resolve(res.data)
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getGakku = () => {
    return new Promise((resolve, reject) => {
      this.accessToken().then((token) => {
        axios
          .get(`${process.env.REACT_APP_CARTO_API_BASEURL}/gakku`, {
            headers: this.headers(token),
          })
          .then((res) => {
            resolve(res.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    })
  }

  getAverageYosoku = (rangeType, bounds, modeColumn = "global", schoolArea) => {
    return new Promise((resolve, reject) => {
      console.log("getAverageYosoku", schoolArea)

      let q = this.getQueryString({
        range_type: rangeType,
        bounds,
        mode_column: modeColumn,
        school_area: schoolArea,
      })

      this.accessToken().then((token) => {
        axios
          .get(
            `${process.env.REACT_APP_CARTO_API_BASEURL}/average_yosoku?${q}`,
            {
              headers: this.headers(token),
            }
          )
          .then((res) => {
            resolve(res.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    })
  }

  getTotalCount = (rangeType, bounds, filter) => {
    return new Promise((resolve, reject) => {
      let q = this.getQueryString({
        range_type: rangeType,
        bounds: bounds,
        filter: filter,
      })

      this.accessToken()
        .then((token) => {
          axios
            .get(
              `${process.env.REACT_APP_CARTO_API_BASEURL}/total_count?${q}`,
              {
                headers: this.headers(token),
              }
            )
            .then((res) => {
              resolve(res.data)
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getAgePieChartData = (filter, rangeType, bounds, limit = 5) => {
    return new Promise((resolve, reject) => {
      this.accessToken()
        .then((token) => {
          let q = this.getQueryString({
            filter,
            range_type: rangeType,
            bounds,
            limit: limit,
          })

          axios
            .get(
              `${process.env.REACT_APP_CARTO_API_BASEURL}/age_pie_chart?${q}`,
              {
                headers: this.headers(token),
              }
            )
            .then((res) => {
              resolve(res.data)
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getInjuryPieChartData = (filter, rangeType, bounds, limit = 5) => {
    return new Promise((resolve, reject) => {
      let q = this.getQueryString({
        filter,
        range_type: rangeType,
        bounds,
        limit,
      })

      this.accessToken()
        .then((token) => {
          axios
            .get(
              `${process.env.REACT_APP_CARTO_API_BASEURL}/injury_pie_chart?${q}`,
              {
                headers: this.headers(token),
              }
            )
            .then((res) => {
              resolve(res.data)
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getPredictDouroData = (
    mode,
    rangeType,
    bounds,
    count = null,
    schoolArea,
    min = 0.1,
    max = 0.9
  ) => {
    return new Promise((resolve, reject) => {
      this.accessToken()
        .then((token) => {
          let q = this.getQueryString({
            mode,
            range_type: rangeType,
            bounds,
            count,
            min,
            max,
            school_area: schoolArea,
          })

          axios
            .get(
              `${process.env.REACT_APP_CARTO_API_BASEURL}/predict_douro?${q}`,
              {
                headers: this.headers(token),
              }
            )
            .then((res) => {
              resolve(res.data)
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getPredictKosatenData = (
    mode,
    rangeType,
    bounds,
    count = null,
    schoolArea,
    min = 0.1,
    max = 0.9
  ) => {
    return new Promise((resolve, reject) => {
      this.accessToken()
        .then((token) => {
          let q = this.getQueryString({
            mode,
            range_type: rangeType,
            bounds,
            count,
            school_area: schoolArea,
            min,
            max,
          })

          axios
            .get(
              `${process.env.REACT_APP_CARTO_API_BASEURL}/predict_kosaten?${q}`,
              {
                headers: this.headers(token),
              }
            )
            .then((res) => {
              resolve(res.data)
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getDouroPredictionList = (request) => {
    return new Promise((resolve, reject) => {
      let q = this.getQueryString(request)
      //      console.log('Douro', 'request', request)
      this.accessToken()
        .then((token) => {
          axios
            .get(
              `${process.env.REACT_APP_CARTO_API_BASEURL}/douro_prediction_list?${q}`,
              {
                headers: this.headers(token),
              }
            )
            .then((res) => {
              //resolve(res.data)
              if (!res.data[0]) {
                console.log("Empty result")
                resolve({
                  rowCount: 0,
                  rowData: [],
                })
              } else {
                resolve({
                  rowCount: res.data[0].rowcount,
                  rowData: res.data[0].rowdata,
                })
              }
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getKosatenPredictionList = (request) => {
    return new Promise((resolve, reject) => {
      let q = this.getQueryString(request)

      this.accessToken()
        .then((token) => {
          axios
            .get(
              `${process.env.REACT_APP_CARTO_API_BASEURL}/kosaten_prediction_list?${q}`,
              {
                headers: this.headers(token),
              }
            )
            .then((res) => {
              //resolve(res.data)
              if (!res.data[0]) {
                console.log("Empty result")
                resolve({
                  rowCount: 0,
                  rowData: [],
                })
              } else {
                resolve({
                  rowCount: res.data[0].rowcount,
                  rowData: res.data[0].rowdata,
                })
              }
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getJissekiDetailData = (cartodbId, mode = "global") => {
    return new Promise((resolve, reject) => {
      this.accessToken()
        .then((token) => {
          axios
            .get(
              `${process.env.REACT_APP_CARTO_API_BASEURL}/jisseki_detail?cartodb_id=${cartodbId}&mode=${mode}`,
              {
                headers: this.headers(token),
              }
            )
            .then((res) => {
              resolve(res.data)
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getKosatenDetailData = (cartodbId, mode = "global") => {
    return new Promise((resolve, reject) => {
      this.accessToken()
        .then((token) => {
          axios
            .get(
              `${process.env.REACT_APP_CARTO_API_BASEURL}/kosaten_detail?cartodb_id=${cartodbId}&mode=${mode}`,
              {
                headers: this.headers(token),
              }
            )
            .then((res) => {
              resolve(res.data)
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getDouroDetailData = (cartodbId, mode = "global") => {
    return new Promise((resolve, reject) => {
      this.accessToken()
        .then((token) => {
          axios
            .get(
              `${process.env.REACT_APP_CARTO_API_BASEURL}/douro_detail?cartodb_id=${cartodbId}&mode=${mode}`,
              {
                headers: this.headers(token),
              }
            )
            .then((res) => {
              resolve(res.data)
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getNotificationMessage = () => {
    return new Promise((resolve, reject) => {
      this.accessToken()
        .then((token) => {
          axios
            .get(
              `${process.env.REACT_APP_CARTO_API_BASEURL}/notification_message`,
              {
                headers: this.headers(token),
              }
            )
            .then((res) => {
              resolve(res.data)
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getVectorTile = (query) => {
    return new Promise((resolve, reject) => {
      this.accessToken().then((token) => {
        axios
          .post(
            `${process.env.REACT_APP_CARTO_API_BASEURL}/map`,
            {
              query: query,
              cartoCss: {},
            },
            {
              headers: this.headers(token),
            }
          )
          .then((res) => {
            //            console.log(res)
            let url = `${process.env.REACT_APP_CARTO_API_BASEURL}/map/${res.data.id}/{z}/{x}/{y}.mvt`
            resolve([url])
          })
          .catch((e) => {})
      })
    })
  }
})()
