import PropTypes from "prop-types"
import { useContext, useEffect, useMemo, useState } from "react"
import { RootDataContext } from "../../../index"
import APIManager from "../../../../../manager/api"
import DataManager from "../../../../../manager/data"

const SOURCE_NAME = "school"
const LAYER_NAME = "school_layer"

const SchoolLayer = (props) => {
  const { state } = useContext(RootDataContext)
  const map = useMemo(() => props.map, [props.map])
  const [tiles, setTiles] = useState()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    APIManager.getVectorTile(DataManager.selectQueryGakku())
      .then((res) => {
        setTiles(res)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    if (!map || !tiles) {
      return
    }

    let source = map.getSource(SOURCE_NAME)
    if (source) {
      source.setTiles(tiles)
      return
    }

    map.addSource(SOURCE_NAME, {
      type: "vector",
      tiles: tiles,
    })

    map.addLayer({
      id: `${LAYER_NAME}_fill`,
      type: "fill",
      source: SOURCE_NAME,
      "source-layer": "layer0",
      minzoom: 10,
      maxzoom: 22,
      layout: {
        visibility: "none",
      },
      paint: {
        "fill-color": "#6dba7f",
        "fill-opacity": 0.75,
      },
    })

    map.addLayer({
      id: `${LAYER_NAME}_line`,
      type: "line",
      source: SOURCE_NAME,
      "source-layer": "layer0",
      minzoom: 10,
      maxzoom: 22,
      layout: {
        visibility: "none",
      },
      paint: {
        "line-color": "#eeeeee",
        "line-width": 3,
      },
    })

    map.addLayer({
      id: `${LAYER_NAME}_label`,
      type: "symbol",
      source: SOURCE_NAME,
      "source-layer": "layer0",
      minzoom: 10,
      maxzoom: 22,
      layout: {
        "text-field": ["get", "a27_007"],
        "text-justify": "center",
        "text-size": ["interpolate", ["linear"], ["zoom"], 6, 8, 12, 12, 18, 18],
        "text-allow-overlap": true,
        "icon-allow-overlap": true,
        visibility: "none",
      },
      paint: {
        "text-color": "#000",
        "text-halo-color": "#FFF",
        "text-halo-width": 1,
      },
    })

    setInitialized(true)
    props.onLoad && props.onLoad()
  }, [map, tiles])

  useEffect(() => {
    if (!map || !initialized) { return }

    map.setLayoutProperty(
      `${LAYER_NAME}_label`,
      "visibility",
      state.viewData.includes("小学校学区") ? "visible" : "none"
    )
     map.setLayoutProperty(
      `${LAYER_NAME}_line`,
       "visibility",
       state.viewData.includes("小学校学区") ? "visible" : "none"
    )
    map.setLayoutProperty(
      `${LAYER_NAME}_fill`,
      "visibility",
      state.viewData.includes("小学校学区") ? "visible" : "none"
    )
  }, [map, initialized, state.viewData])

  return null
}

export const Order = (map) => {
  map.moveLayer(`${LAYER_NAME}_fill`)
  map.moveLayer(`${LAYER_NAME}_line`)
  map.moveLayer(`${LAYER_NAME}_label`)
}

SchoolLayer.propTypes = {
  map: PropTypes.any,
  onLoad: PropTypes.func,
}
export default SchoolLayer
