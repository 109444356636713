import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { RootDataContext } from "../../../index"
import APIManager from "../../../../../manager/api"

const styles = {
  root: {
    backgroundColor: "#f3f3f3",
    color: "black",
    padding: "12px",
  },
  value: {
    textAlign: "center",
    marginTop: "6px",
    padding: "8px",
    backgroundColor: "white",
    borderRadius: "6px",
  },
}

const RootPredictAverageCard = (props) => {
  const { state } = useContext(RootDataContext)
  const [average, setAverage] = useState(null)

  useEffect(() => {
    if (!state.mapViewBounds) {
      return
    }

    APIManager.getAverageYosoku(
      state.rangeType,
      state.mapViewBounds,
      state.predictionMode,
      state.schoolArea?.name
    ).then((row) => {
      if (!row) {
        setAverage("--")
        return
      }
      let p = parseFloat(row.avg)
      if (!p || isNaN(p)) {
        setAverage("--")
        return
      }
      setAverage(p.toFixed(2))
    })
  }, [
    state.predictionMode,
    state.rangeType,
    state.mapViewBounds,
    state.schoolArea,
  ])

  return (
    <Box sx={{ ...styles.root, ...props.sx }}>
      <Typography>事故発生リスク（平均値）</Typography>
      <Box sx={styles.value}>
        {average && (
          <Typography color="#3c6960" variant="h4">
            {average}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

RootPredictAverageCard.propTypes = {
  sx: PropTypes.object,
}

export default RootPredictAverageCard
