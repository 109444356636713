import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { AgGridReact } from "ag-grid-react"
import { Box, IconButton, Typography } from "@mui/material"
import { RootDataContext } from "../index"
import LOCALE_JA from "../../../resources/aggrid/locale.ja"
import APIManager from "../../../manager/api"
import { DoubleArrow as DoubleArrowIcon } from "@mui/icons-material"
import { Component } from "ag-grid-community"
import "ag-grid-enterprise"
import {NoRowsView} from "./index";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: "white",
    margin: "4px !important",
  },
  grid: {
    flexGrow: 1,
  },
}


const RootDouroListView = (props) => {
  const { setViewType, setPinLocation } = useContext(RootDataContext)
  const { state } = useContext(RootDataContext)
  const gridRef = useRef()
  const [rowData, setRowData] = useState(null)

  const columnDef = useMemo(
    () => [
      {
        headerName: "地図",
        cellRenderer: (param) => {
          return (
            <IconButton
              size="small"
              onClick={() => {
                setPinLocation({
                  lngLat: [param.data.longitude, param.data.latitude],
                  cartodb_id: param.cartodb_id,
                  type: "douro",
                })
              }}
            >
              <DoubleArrowIcon style={{ color: "#666" }} fontSize="small" />
            </IconButton>
          )
        },
        width: 60,
      },
      { field: "rn", headerName: "順位", filter: "agNumberColumnFilter" },
      {
        field: "risk",
        headerName: "リスクレベル",
        filter: "agNumberColumnFilter",
      },
      { field: "road_name", headerName: "名称", filter: "agTextColumnFilter" },
      {
        field: "address",
        headerName: "周辺住所",
        filter: "agTextColumnFilter",
      },
      { field: "school_elementary_name", headerName: "学区", filter: false, menuTabs: [], },
      { field: "zone30", headerName: "ゾーン30", filter: "agTextColumnFilter" },
      { field: "speed", headerName: "規制速度（km/h）", filter: "agTextColumnFilter" },
      {
        field: "speed_median",
        headerName: "走行速度（km/h）", filter: "agNumberColumnFilter"
      },
      { field: "manager_jp", headerName: "道路管理者", filter: "agTextColumnFilter" },
      {
        field: "pt0_2020",
        headerName: "人口指数（人）",
        filter: "agNumberColumnFilter",
      },
    ],
    []
  )

  return (
    <Box sx={{ ...styles.root, ...props.sx }}>
      <Typography sx={styles.title}>道路事故予測</Typography>
      <AgGridReact
        localeText={LOCALE_JA}
        className={"ag-theme-balham"}
        containerStyle={styles.grid}
        ref={gridRef}
        columnDefs={columnDef}
        defaultColDef={{
          sortable: true,
          resizable: true,
          filter: true,
          menuTabs: ["filterMenuTab"],
        }}
        rowModelType="serverSide"
        serverSideDatasource={{
          getRows(params) {
            let req = { ...params.request }
            // school_elementary_name: state.schoolArea?.name,
            if (state.schoolArea) {
                req.filterModel["school_elementary_name"] = {
                    filterType: "text",
                    type: "equals",
                    filter: state.schoolArea.name,
                }
            }
            APIManager.getDouroPredictionList(req)
              .then((res) => {
                console.log(res)
                params.success(res)
              })
              .catch((e) => {
                console.log(e)
                params.fail()
              })
          },
        }}
        pagination={true}
        paginationAutoPageSize={true}
        overlayLoadingTemplate={
          '<span style="padding: 8px 16px; border: 1px solid #000; border-radius: 4px;"><img src="./loading.gif" style="width: 26px; vertical-align: middle">読込中・・・</span>'
        }
        overlayNoRowsTemplate={
          '<span style="padding: 8px 16px; border: 1px solid #000; border-radius: 4px;">対象データなし</span>'
        }
      />
    </Box>
  )
}

RootDouroListView.propTypes = {
  sx: PropTypes.object,
  filter: PropTypes.object,
}

export default RootDouroListView
