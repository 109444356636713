import React, { useContext, useEffect, useRef, useState } from "react"
import { Box, IconButton, Typography } from "@mui/material"
import {
  Add as AddIcon,
  Layers as LayersIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material"
import maplibregl from "maplibre-gl"
import PropTypes from "prop-types"
import APIManager from "../../../../manager/api"
import PredictKosatenLayer, {
  Order as PredictKosatenOrder,
} from "./layers/predictKosaten"
import PredictDouroLayer, {
  Order as PredictDouroOrder,
} from "./layers/predictDouro"
import JissekiLayer, { Order as JissekiOrder } from "./layers/jisseki"
import GyoseikaiLayer, { Order as GyoseikaiOrder } from "./layers/gyouseikai"
import SchoolLayer, { Order as SchoolOrder } from "./layers/school"

import DouroLegends from "./legend/douro"
import KousatenLegends from "./legend/kousaten"
import JinryuLegends from "./legend/jinryu"
import JissekiLegends from "./legend/jiseki"

import { RootDataContext } from "../../index"
import DataManager from "../../../../manager/data"
import "./road_detail_table.css"

const baseLayers = {
  dark: {
    name: "ダークモード",
    url: "https://b.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
  },
  standard: {
    name: "地理院タイル（標準）",
    url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
  },
  pale: {
    name: "地理院タイル（淡色）",
    url: "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
  },
  photo: {
    name: "地理院タイル（写真）",
    url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
  },
}

const styles = {
  legends: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    position: "absolute",
    left: "12px",
    top: "12px",
    zIndex: 3,
  },
}

const BASE_SOURCE_NAME = "raster-tiles"
const BASE_LAYER_NAME = "raster-tiles-layer"

let token = null
let marker
let popup

const MapView = (props) => {
  const mapRef = useRef()
  const [map, setMap] = useState()
  const { state, setMapViewBounds, setPinLocation, setMapCenter } =
    useContext(RootDataContext)
  const [openBaseLayerMenu, setOpenBaseLayerMenu] = useState(false)
  const [baseLayer, setBaseLayer] = useState(baseLayers.pale)
  const [hideLegends, setHideLegends] = useState(false)

  const [clickJissekiData, setClickJissekiData] = useState()
  const [clickDouroData, setClickDouroData] = useState()
  const [clickKosatenData, setClickKosatenData] = useState()

  const [predictDouroLoaded, setPredictDouroLoaded] = useState(false)
  const [predictKosatenLoaded, setPredictKosatenLoaded] = useState(false)
  const [jissekiLoaded, setJissekiLoaded] = useState(false)
  const [gyoseikaiLoaded, setGyoseikaiLoaded] = useState(false)
  const [schoolLoaded, setSchoolLoaded] = useState(false)

  useEffect(() => {
    let inv = setInterval(() => {
      APIManager.accessToken().then((t) => {
        token = t
      })
    }, 1000)

    return () => {
      clearInterval(inv)
    }
  }, [token])

  useEffect(() => {
    if (!mapRef || !mapRef.current) {
      return
    }

    const m = new maplibregl.Map({
      container: mapRef.current,
      pitchWithRotate: false,
      dragRotate: false,
      customAttribution: `© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, © <a href="https://carto.com/about-carto/">CARTO</a>`,
      attributionControl: true,
      minZoom: 10,
      maxZoom: 20,
      style: {
        glyphs: "https://maps.gsi.go.jp/xyz/noto-jp/{fontstack}/{range}.pbf",
        version: 8,
        sources: {
          "raster-tiles": {
            type: "raster",
            tiles: [baseLayer.url],
            tileSize: 256,
          },
        },
        layers: [
          {
            id: "base-tiles-layer",
            type: "raster",
            source: "raster-tiles",
            minzoom: 0,
            maxzoom: 22,
          },
        ],
      },
      center: [
        parseFloat(process.env.REACT_APP_MAP_INITIAL_LONGITUDE),
        parseFloat(process.env.REACT_APP_MAP_INITIAL_LATITUDE),
      ],
      zoom: parseInt(process.env.REACT_APP_MAP_INITIAL_ZOOM),
      transformRequest: (url, resourceType) => {
        if (url.match(/.mvt$/)) {
          //          console.log(url, resourceType)
          return {
            url: url,
            headers: {
              Accept: "application/x-protobuf, appliction/json, text/html",
              AuthorizationToken: `Bearer ${APIManager.lastToken}`,
              "accept-encoding": "gzip, deflate, br",
            },
          }
        }
        return null
      },
    })
    m.on("load", () => {
      setMap(m)
      setMapViewBounds([
        m.getBounds().getNorthEast(),
        m.getBounds().getSouthWest(),
      ])
    })
      .on("moveend", () => {
        //        console.log(m.getBounds())
        setMapViewBounds([
          m.getBounds().getNorthEast(),
          m.getBounds().getSouthWest(),
        ])
        setMapCenter({ ...m.getCenter(), zoom: m.getZoom() })
      })
      .on("click", () => {
        marker?.remove()
        marker = null
      })
  }, [mapRef])

  useEffect(() => {
    if (!map || !baseLayer || !baseLayer.url) {
      return
    }

    //    console.log('[BaseMap]', baseLayer, JSON.stringify(map.getSource(BASE_SOURCE_NAME)))
    //    let source = map.getSource(BASE_SOURCE_NAME)
    //    debugger
    //    return
    map.getSource(BASE_SOURCE_NAME).tiles = [baseLayer.url]
    map.style.sourceCaches[BASE_SOURCE_NAME].clearTiles()
    map.style.sourceCaches[BASE_SOURCE_NAME].update(map.transform)
    map.triggerRepaint()
  }, [map, baseLayer])

  useEffect(() => {
    if (!map || !state.pinLocation) {
      return
    }

    marker?.remove()

    marker = new maplibregl.Marker()
      .setLngLat(state.pinLocation.lngLat)
      .addTo(map)

    map.flyTo({
      center: state.pinLocation.lngLat,
      essential: true,
      zoom: 18,
    })

    setPinLocation(null)
  }, [map, state.pinLocation])

  useEffect(() => {
    if (!map) {
      return
    }
    if (!clickDouroData && !clickKosatenData && !clickJissekiData) {
      return
    }

    let to = setTimeout(() => {
      let type
      let cartodbId
      let lngLat
      let func
      if (clickJissekiData) {
        //        console.log(clickJissekiData)
        cartodbId = clickJissekiData.cartodb_id
        lngLat = clickJissekiData.lngLat
        type = "jisseki"
        func = DataManager.getJissekiDetailContent
      } else if (clickKosatenData) {
        //        console.log(clickKosatenData)
        cartodbId = clickKosatenData.cartodb_id
        lngLat = clickKosatenData.lngLat
        type = "kosaten"
        func = DataManager.getKosaitenDetailContent
      } else if (clickDouroData) {
        //        console.log(clickDouroData)
        cartodbId = clickDouroData.cartodb_id
        lngLat = clickDouroData.lngLat
        type = "douro"
        func = DataManager.getDouroDetailContent
      }

      if (func && cartodbId && lngLat && state.predictionMode) {
        popup = new maplibregl.Popup()
          .setLngLat(lngLat)
          .setHTML("<div>読込中...</div>")
          .addTo(map)

        popup.on("close", () => {
          setClickJissekiData(null)
          setClickDouroData(null)
          setClickKosatenData(null)
        })

        func(cartodbId, state.predictionMode).then((content) => {
          popup.setHTML(content)
        })
      }
    }, 500)

    return () => {
      clearTimeout(to)
      popup?.remove()
    }
  }, [
    map,
    clickJissekiData,
    clickDouroData,
    clickKosatenData,
    state.predictionMode,
  ])

  useEffect(() => {
    if (!map || !predictKosatenLoaded || !predictDouroLoaded) {
      return
    }
    GyoseikaiOrder(map)
    SchoolOrder(map)
    PredictDouroOrder(map)
    PredictKosatenOrder(map)
    JissekiOrder(map)
  }, [
    map,
    predictKosatenLoaded,
    predictDouroLoaded,
    jissekiLoaded,
    gyoseikaiLoaded,
    schoolLoaded,
  ])

  useEffect(() => {
    if (!map) {
      return
    }

    map.resize()
  }, [map, state.visibleRightPane])

  useEffect(() => {
    if (!map || !state.schoolArea) {
      return
    }
    console.log(state.schoolArea)

    if (state.schoolArea.polygon) {
      let geojson = JSON.parse(state.schoolArea.polygon)
      let coordinates = geojson.coordinates[0]
      let bounds = new maplibregl.LngLatBounds(coordinates[0], coordinates[1])
      coordinates.map((c) => {
        bounds.extend(c)
      })
      map.fitBounds(bounds, {
        padding: 80,
      })
    } else {
      map.flyTo({
        center: [state.schoolArea.lng, state.schoolArea.lat],
        zoom: 15,
      })
    }
  }, [map, state.schoolArea])

  useEffect(() => {
    marker?.remove()
    popup?.remove()
  }, [
    state.viewData,
    state.timeslotFilter,
    state.ageFilter,
    state.typeFilter,
    state.injuryFilter,
    state.weatherFilter,
  ])

  const onZoomOut = () => {
    if (!map) {
      return
    }
    map.flyTo({ center: map.getCenter(), zoom: map.getZoom() - 1 })
  }

  const onZoomIn = () => {
    if (!map) {
      return
    }
    map.flyTo({ center: map.getCenter(), zoom: map.getZoom() + 1 })
  }

  return (
    <Box
      sx={{ position: "relative", width: "100%", height: "100%", ...props.sx }}
    >
      <Box
        ref={mapRef}
        sx={{ position: "relative", width: "100%", height: "100%" }}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: "0",
            left: "0",
            zIndex: "1",
            backgroundColor: "#ffffff4d",
            padding: "2px",
          }}
        >
          <Typography variant="small">
            「モバイル空間統計®」は（株）NTTドコモの登録商標です
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "32px",
            right: "8px",
            zIndex: "1",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#ffffff",
            border: "1px solid #00000033",
          }}
        >
          <Box
            sx={{
              border: "1px solid #00000033",
              width: "32px",
              height: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton size="small" onClick={onZoomIn}>
              <AddIcon size="small" />
            </IconButton>
          </Box>
          <Box
            sx={{
              border: "1px solid #00000033",
              width: "32px",
              height: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-1px",
            }}
          >
            <IconButton size="small" onClick={onZoomOut}>
              <RemoveIcon size="small" />
            </IconButton>
          </Box>
        </Box>
        <PredictKosatenLayer
          map={map}
          onLoad={() => setPredictKosatenLoaded(true)}
          onClick={(e) => {
            setClickKosatenData({
              lngLat: e.lngLat,
              ...e.features[0].properties,
            })
          }}
        />
        <PredictDouroLayer
          map={map}
          onLoad={() => setPredictDouroLoaded(true)}
          onClick={(e) => {
            console.log(e)
            setClickDouroData({ lngLat: e.lngLat, ...e.features[0].properties })
          }}
        />
        <JissekiLayer
          map={map}
          onLoad={() => setJissekiLoaded(true)}
          onClick={(e) => {
            setClickJissekiData({
              lngLat: e.lngLat,
              ...e.features[0].properties,
            })
          }}
        />
        <GyoseikaiLayer map={map} onLoad={() => setGyoseikaiLoaded(true)} />
        <SchoolLayer map={map} onLoad={() => setSchoolLoaded(true)} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "8px",
          right: "8px",
          width: "44px",
          height: "44px",
          backgroundColor: "white",
          borderRadius: "4px",
          border: "1px solid #777373",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onMouseEnter={() => {
          setOpenBaseLayerMenu(true)
        }}
      >
        <LayersIcon></LayersIcon>
      </Box>
      <Box
        sx={{
          display: openBaseLayerMenu ? "box" : "none",
          position: "absolute",
          top: "8px",
          right: "8px",
          backgroundColor: "white",
          border: "1px solid #000",
          borderRadius: "4px",
        }}
        onMouseLeave={() => {
          setOpenBaseLayerMenu(false)
        }}
      >
        <ul
          style={{
            listStyle: "none",
            padding: "8px",
            margin: 0,
            fontSize: "12px",
          }}
        >
          {Object.keys(baseLayers).map((k) => (
            <li key={k}>
              <label>
                <input
                  type="radio"
                  name="basemap"
                  checked={baseLayer.name === baseLayers[k].name}
                  onClick={() => setBaseLayer(baseLayers[k])}
                />
                {baseLayers[k].name}
              </label>
            </li>
          ))}
        </ul>
      </Box>
      {!hideLegends && (
        <Box sx={styles.legends}>
          {state.viewData.includes("道路事故予測") && <DouroLegends />}
          {state.viewData.includes("交差点事故予測") && <KousatenLegends />}
          {state.viewData.includes("人流・人口") && <JinryuLegends />}
          {state.viewData.includes("事故実績") && <JissekiLegends />}
        </Box>
      )}
    </Box>
  )
}

MapView.propTypes = {
  sx: PropTypes.object,
  mapOptions: PropTypes.any,
}

export default MapView
