import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"

const styles = {
  back: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: "3000",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(143,143,143,0.49)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    position: "relative",
    backgroundColor: "white",
    borderRadius: "8px",
    border: "1px solid #333",
    width: "600px",
    height: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",
  },
  close: {
    position: "absolute !important",
    top: "8px",
    right: "8px",
  },
}

const RootExportView = (props) => {
  const [dataType, setDataType] = useState(["jisseki"])
  const [format, setFormat] = useState(["csv"])

  const handleDataType = (e) => {
    setDataType([e.target.value])
  }

  const handleFormat = (e) => {
    setFormat([e.target.value])
  }

  const handleExport = () => {
    props.onClose && props.onClose()
  }

  return (
    <Box sx={styles.back}>
      <Box sx={styles.root}>
        <IconButton sx={styles.close} onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
        <Box>
          <Typography>出力データ</Typography>
          <ToggleButtonGroup value={dataType} onChange={handleDataType}>
            <ToggleButton value="jisseki">実績</ToggleButton>
            <ToggleButton value="kosaten">交差点事故予測</ToggleButton>
            <ToggleButton value="douro">道路事故予測</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box>
          <Typography>データ出力形式</Typography>
          <ToggleButtonGroup value={format} onChange={handleFormat}>
            <ToggleButton value="csv">CSV</ToggleButton>
            <ToggleButton value="gpx">GPX</ToggleButton>
            <ToggleButton value="shp">SHP</ToggleButton>
            <ToggleButton value="geojson">GeoJSON</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Button onClick={handleExport}>エクスポート</Button>
      </Box>
    </Box>
  )
}

RootExportView.propTypes = {
  onClose: PropTypes.func,
}

export default RootExportView
