import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { RootDataContext } from "../../../index"
import DataManager from "../../../../../manager/data"
import APIManager from "../../../../../manager/api"

const SOURCE_NAME = "prediction_kosaten_yosoku"
const LAYER_NAME = "prediction_kosaten_yosoku_layer"
const PredictKosatenLayer = (props) => {
  const { state } = useContext(RootDataContext)
  const map = useMemo(() => props.map, [props.map])
  const canvas = useMemo(() => map?.getCanvasContainer(), [map])
  const [tiles, setTiles] = useState()
  const [initialized, setInitialized] = useState(false)

  const query = useMemo(() => {
    if (!state.predictionMode) {
      return null
    }
    return DataManager.selectQueryKosatenjikoYosoku(
      state.predictionMode,
      state.schoolArea?.name
    )
  }, [state.predictionMode, state.schoolArea])

  useEffect(() => {
    if (!map || !query) {
      return
    }
    APIManager.getVectorTile(query)
      .then((res) => {
        console.log(res)
        //        map.getSource(SOURCE_NAME).setTiles(res)
        setTiles(res)
      })
      .catch((e) => {})
  }, [map, query])

  useEffect(() => {
    if (!map || !canvas || !tiles) {
      return
    }
    let source = map.getSource(SOURCE_NAME)
    if (source) {
      source.setTiles(tiles)
    } else {
      map.addSource(SOURCE_NAME, {
        type: "vector",
        tiles: tiles,
      })
      map.addLayer({
        id: LAYER_NAME + "_circle",
        type: "circle",
        source: SOURCE_NAME,
        "source-layer": "layer0",
        minzoom: 10,
        maxzoom: 22,
        layout: {},
        paint: {
          //"circle-color": "#ff0", //                 marker-fill: ramp([risk], (#1a9641, #a6d96a, #ffffbf, #fdae61, #d7191c), (0.1,0.25,0.5,0.75,1.1), '>', jenks());
          "circle-color": [
            "case",
            ["<", ["get", "risk"], 0.1],
            "#1a9641",
            ["all", [">=", ["get", "risk"], 0.1], ["<", ["get", "risk"], 0.25]],
            "#a6d96a", // allてのはandってこと
            ["all", [">=", ["get", "risk"], 0.25], ["<", ["get", "risk"], 0.5]],
            "#ffffbf",
            ["all", [">=", ["get", "risk"], 0.5], ["<", ["get", "risk"], 0.75]],
            "#fdae61",
            "#d7191c",
          ],
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            2,
            15,
            4,
            18,
            7,
          ],
          "circle-stroke-color": "#FFF",
          "circle-stroke-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            1,
            15,
            2,
            18,
            3,
          ],
          "circle-stroke-opacity": 0.65,
        },
      })
    }

    map
        .on("click", `${LAYER_NAME}_circle`, (e) => {
          props.onClick && props.onClick(e)
        })
        .on("mouseenter", `${LAYER_NAME}_circle`, () => {
          canvas.style.cursor = "pointer"
        })
        .on("mouseleave", `${LAYER_NAME}_circle`, () => {
          canvas.style.cursor = "grab"
        })
    setInitialized(true)
    props.onLoad && props.onLoad()

  }, [map, canvas, tiles])

  useEffect(() => {
    if (!map || !initialized) { return }

    map.setLayoutProperty(`${LAYER_NAME}_circle`, 'visibility', state.viewData.includes("交差点事故予測") ? "visible": "none")
  }, [map, initialized, state.viewData])


  return null
}

export const Order = (map) => {
  map.moveLayer(`${LAYER_NAME}_circle`)
}

PredictKosatenLayer.propTypes = {
  map: PropTypes.any,
  onLoad: PropTypes.func,
  onClick: PropTypes.func,
}

export default PredictKosatenLayer
