import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  setRef,
  Typography,
} from "@mui/material"
import UseRootViewData, { ViewType } from "./data"
import NotificationView from "./notification"
import HeaderView from "./map/header"
import LeftPanelView from "./map/leftPanel"
import RightPanelView from "./map/rightPanel"
import OptionView from "./option"
import LogoImage from "../../resources/logo.png"
import LoginView from "../login"
import dayjs from "dayjs"
import MapContents from "./map"
import ListContents from "./list"
import ExportView from "./export"
import StateDialog from "./stateDialog"
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react"
import PlaceSearch from "./placeSearch"
import RootPlaceSearchView from "./placeSearch"
import TermsOfServiceView from "../terms_of_service"
import RootHelpView from "./help"
import DataManager from "../../manager/data"
import { AuthDataContext } from "../../App"
import RootResetPasswordView from "./resetPassword"
import APIManager from "../../manager/api"

import { initializeApp } from "firebase/app"
import { getAnalytics, setUserId } from "firebase/analytics"
import { Analytics } from "@mui/icons-material"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const styles = {
  root: {
    backgroundColor: "white",
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    minWidth: "1600px",
    overflowX: "auto",
  },
  viewSwitchButton: {
    backgroundColor: "#c2c2c2 !important",
    borderRadius: "0 !important",
    color: "white !important",
    width: "165px !important",
    height: "35px !important",
    margin: "auto !important",
  },
  activeButton: {
    backgroundColor: "#3c6960 !important",
  },
  nativeButton: {
    background:
      "linear-gradient(0deg, rgba(117,110,231,1) 0%, rgba(29,29,170,1) 60%, rgba(209,209,255,1) 100%)",
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    gap: "5px",
  },
  mainPanel: {},
  rightPanel: {
    width: "300px",
    height: "100%",
    margin: "0 8px",
  },
  placeSearch: {
    width: "300px",
  },
}

export const RootDataContext = React.createContext()

const RootView = (props) => {
  const { user, logout, isLoading, getAccessTokenSilently } = useAuth0()
  const useRootData = UseRootViewData()
  const { state, setViewType, setUser } = useRootData
  const [notificationMessage, setNotificationMessage] = useState(null)
  const [refresh, setRefresh] = useState()
  const [showExport, setShowExport] = useState()
  const [showHelp, setShowHelp] = useState(false)
  const [accessToken, setAccessToken] = useState()
  const [showPolicy, setShowPolicy] = useState(false)
  const [showFirstResetPassword, setShowFirstResetPassword] = useState(false)

  useEffect(() => {
    setRefresh(dayjs())
  }, [state.userUid])

  useEffect(() => {
    if (isLoading) return

    //    console.log(JSON.stringify(user))
  }, [isLoading])

  const getUser = async (force) => {
    if (!force && state.user) {
      return
    }

    APIManager.userInfo()
      .then((res) => {
        setUser(res)
      })
      .catch((e) => {
        console.log("[Auth0]", "login error", e)

        if (e.error === "login_required") {
          alert(
            "ユーザセッションが切断されました\n※シークレットモードでの利用は正常に動かない可能性があります"
          )
          logout({ returnTo: window.location.origin })
        } else {
          if (process.env.NODE_ENV !== "development") {
            alert(
              `ユーザ取得エラー:${e.message}(${e.error})\n管理者へ連絡してください`
            )
          }
        }
      })
  }

  useEffect(() => {
    //    console.log("[State User]", "updated", state.user)
    if (!state.user) {
      return
    }

    // ユーザが確定してからのAnalytics
    const app = initializeApp(firebaseConfig)
    const analytics = getAnalytics(app)
    setUserId(analytics, state.user.user_id)

    if (!state.user.user_metadata?.own_password) {
      setShowFirstResetPassword(true)
    } else if (
      !state.user.user_metadata?.policy_agreed ||
      parseInt(state.user.user_metadata.policy_agreed) !==
        parseInt(process.env.REACT_APP_POLICY_VERSION)
    ) {
      // TODO: 規約変更時対応(versionチェック)
      setShowPolicy(true)
    }
  }, [state.user])

  useEffect(() => {
    APIManager.accessTokenFunc = getAccessTokenSilently

    getUser()

    APIManager.getNotificationMessage().then((res) => {
      setNotificationMessage(res)
    })

    getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:current_user",
    })
      .then((res) => {
        //        console.log("[Get token]", "success", res)
      })
      .catch((e) => {
        console.log("[Get token]", "error", e)
      })

    //
    // try {
    //   const accessToken = await getAccessTokenSilently({
    //     audience: `https://${domain}/api/v2/`,
    //     scope: "read:current_user",
    //   });
    //
    //   const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
    //
    //   const metadataResponse = await fetch(userDetailsByIdUrl, {
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   });
    //
    //   const { user_metadata } = await metadataResponse.json();
    //
    //   setUserMetadata(user_metadata);
    // } catch (e) {
    //   console.log(e.message);
    // }
  }, [])

  return (
    <RootDataContext.Provider value={useRootData}>
      <Box sx={{ ...styles.root, ...props.sx }}>
        <Box
          style={{
            margin: "8px 0",
            display: "flex",
            flexDirection: "row",
            gap: "6px",
            backgroundColor: "#f3f3f3",
            alignItems: "center",
          }}
        >
          {/* 上部表示切り替えボタン */}
          <Button
            sx={{
              ...styles.viewSwitchButton,
              ...(state.viewType === ViewType.map ? styles.activeButton : null),
            }}
            onClick={() => setViewType(ViewType.map)}
          >
            地図
          </Button>
          <Button
            sx={{
              ...styles.viewSwitchButton,
              ...(state.viewType === ViewType.list
                ? styles.activeButton
                : null),
            }}
            onClick={() => setViewType(ViewType.list)}
          >
            リスト
          </Button>
          <Box sx={{ m: 0.5 }} />
          <OptionView
            onLogout={() => logout({ returnTo: window.location.origin })}
            onExport={() => setShowExport(true)}
            onHelp={() => setShowHelp(true)}
          />
          <Box sx={{ m: 0.5 }} />
          {APIManager.accessTokenFunc && <RootPlaceSearchView sx={styles.placeSearch} />}
          <Box style={{ flexGrow: 1 }} />
          <img src={LogoImage} style={{ height: "43px" }} />
          {process.env.REACT_APP_ENV_NAME !== "prod" && (
            <Typography>({process.env.REACT_APP_ENV_NAME})</Typography>
          )}
        </Box>
        <Box
          style={{
            backgroundColor: "white",
            width: "100%",
            height: "100%",
          }}
        >
          {APIManager.accessTokenFunc && (<Box sx={styles.content}>
            {state.viewType === ViewType.map && <MapContents />}
            {state.viewType === ViewType.list && <ListContents />}
          </Box>)}
        </Box>
        {/* お知らせ */}
        {APIManager.accessTokenFunc && notificationMessage && (
          <NotificationView
            message={notificationMessage}
            onLoopFinish={(e, i, j) => {
              setNotificationMessage(null)
            }}
          />
        )}
      </Box>
      {state.loadingCount > 0 && (
        <Box
          sx={{
            backgroundColor: "rgba(194,193,193,0.12)",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2000,
          }}
        >
          <CircularProgress sx={{ color: "#476731" }} />
        </Box>
      )}
      {showExport && (
        <ExportView
          onClose={() => {
            setShowExport(null)
          }}
        />
      )}
      <StateDialog />
      {showPolicy && (
        <TermsOfServiceView
          onClose={(message) => {
            // TODO: エラー時にメッセージ
            setShowPolicy(false)
          }}
        />
      )}
      {showHelp && <RootHelpView onClose={() => setShowHelp(false)} />}
      <Modal open={showFirstResetPassword}>
        <RootResetPasswordView
          first={true}
          force={true}
          onChanged={(user) => {
            setUser(user)
          }}
          onClose={() => {
            setShowFirstResetPassword(false)
          }}
        />
      </Modal>
    </RootDataContext.Provider>
  )
}

RootView.propTypes = {
  sx: PropTypes.object,
  onLogout: PropTypes.func,
}

export default RootView
