import React, { useEffect, useMemo, useState } from "react"
import { useContext } from "react"
import { RootDataContext } from "./index"
import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"

const RootStateDialog = (props) => {
  const { state, closeStateDialog } = useContext(RootDataContext)

  const handleClose = () => {
    state.stateDialogOnClose && state.stateDialogOnClose()
    closeStateDialog()
  }

  return (
    <Dialog open={state.openStateDialog} onClose={handleClose}>
      <DialogTitle>{state.stateDialogTitle}</DialogTitle>
      <DialogContent>{state.stateDialogContent}</DialogContent>
      {state.stateDialogActions && (
        <DialogActions>
          {state.stateDialogActions.map((action, i) => {
            return (
              <Button
                key={`${action.title}-${i}`}
                color={action.color}
                onClick={() => {
                  action.onClick && action.onClick(action.title)
                  handleClose()
                }}
              >
                {action.title}
              </Button>
            )
          })}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default RootStateDialog
