import PropTypes from "prop-types"
import React from "react"
import { Box } from "@mui/material"
import { Pie } from "react-chartjs-2"
import { ArcElement, Chart as ChartJS, Tooltip } from "chart.js"

ChartJS.register(ArcElement, Tooltip)

const RootAggregateCardPieChartView = (props) => {
  return (
    <Box sx={{ color: "white", ...props.sx }}>
      <Pie data={props.data} />
    </Box>
  )
}

RootAggregateCardPieChartView.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.any,
}

export default RootAggregateCardPieChartView
