import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { RootDataContext } from "../../../index"
import DataManager from "../../../../../manager/data"
import APIManager from "../../../../../manager/api"

const SOURCE_NAME = "jisseki"
const LAYER_NAME = "jisseki_layer"

const JissekiLayer = (props) => {
  const { state } = useContext(RootDataContext)
  const map = useMemo(() => props.map, [props.map])
  const canvas = useMemo(() => map?.getCanvasContainer(), [map])
  const [tiles, setTiles] = useState()
  const [initialized, setInitialized] = useState(false)

  const query = useMemo(() => {
    return DataManager.selectQueryJikojisseki(
      {
        timeslot: state.timeslotFilter,
        age: state.ageFilter,
        type: state.typeFilter,
        injury: state.injuryFilter,
        weather: state.weatherFilter,
      },
      state.schoolArea?.name
    )
  }, [
    state.schoolArea,
    state.timeslotFilter,
    state.ageFilter,
    state.typeFilter,
    state.injuryFilter,
    state.weatherFilter,
  ])

  useMemo(() => {
    if (!map || !query) {
      return
    }
    APIManager.getVectorTile(query)
      .then((res) => {
        setTiles(res)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [map, query])

  useEffect(() => {
    if (!map || !canvas || !tiles) {
      return
    }

    let source = map.getSource(SOURCE_NAME)
    if (source) {
      source.setTiles(tiles)
      return
    }

    map.addSource(SOURCE_NAME, {
      type: "vector",
      tiles: tiles,
    })

    map.addLayer({
      id: `${LAYER_NAME}_circle`,
      type: "circle",
      source: SOURCE_NAME,
      "source-layer": "layer0",
      minzoom: 10,
      maxzoom: 22,
      layout: {
        visibility: state.viewData.includes("事故実績") ? "visible" : "none",
      },
      paint: {
        "circle-color": "#422fbc",
        "circle-radius": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          5,
          15,
          7,
          18,
          8,
        ],
        "circle-stroke-color": "#fff",
        "circle-stroke-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          1,
          15,
          2,
          18,
          3,
        ],
        "circle-stroke-opacity": 0.65,
      },
    })

    map
      .on("click", `${LAYER_NAME}_circle`, (e) => {
        props.onClick && props.onClick(e)
      })
      .on("mouseenter", `${LAYER_NAME}_circle`, () => {
        canvas.style.cursor = "pointer"
      })
      .on("mouseleave", `${LAYER_NAME}_circle`, () => {
        canvas.style.cursor = "grab"
      })
    setInitialized(true)
    props.onLoad && props.onLoad()
  }, [map, canvas, tiles])

  useEffect(() => {
    if (!map || !initialized) {
      return
    }

    console.log(
      "Jisseki",
      "set visibility",
      state.viewData.includes("事故実績")
    )

    map.setLayoutProperty(
      `${LAYER_NAME}_circle`,
      "visibility",
      state.viewData.includes("事故実績") ? "visible" : "none"
    )
  }, [map, setInitialized, state.viewData])

  return null
}

export const Order = (map) => {
  map.moveLayer(`${LAYER_NAME}_circle`)
}

JissekiLayer.propTypes = {
  map: PropTypes.any,
  onLoad: PropTypes.func,
  onClick: PropTypes.func,
}

export default JissekiLayer
