
import axios from "axios"



export const Query = (sql, params = {}) => {
  return new Promise((resolve, reject) => {
    try {
      params["api_key"] = process.env.REACT_APP_CARTO_API_KEY
      if (!sql) {
        return resolve(null)
      }
      let queryString = sql
        .replace(/\n/g, " ")
        .replace(/ +/g, " ")
        .replace(/ +/g, " ")
      params["q"] = encodeURI(queryString)
      // &&の変換、+の変換
      params["q"] = params["q"].replace(/&&/g, "%26%26")
      params["q"] = params["q"].replace(/\+/g, "%2B")

      let queryValues = []
      Object.keys(params).forEach((v, i) =>
        queryValues.push(`${v}=${params[v]}`)
      )
      let url = `${
        process.env.REACT_APP_CARTO_SERVER_URL
      }/api/v2/sql?${queryValues.join("&")}`

      axios.get(url).then((res) => {
        resolve(res)
      })
    } catch (e) {
      reject(e)
    }
  })
}
export const Rows = (sql, params = {}) => {
  return new Promise((resolve, reject) => {
    Query(sql, params)
      .then((ret) => {
        resolve(ret?.data?.rows)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const First = (sql, params = {}) => {
  return new Promise((resolve, reject) => {
    Rows(sql, params)
      .then((ret) => {
        resolve(ret?.shift())
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const One = (sql, params = {}, columnName = null) => {
  return new Promise((resolve, reject) => {
    Query(sql, params)
      .then((ret) => {
        let colName = columnName ?? Object.keys(ret?.data?.fields ?? [])[0]
        let row = ret?.data?.rows?.shift()
        if (!row) {
          return resolve(null)
        }
        resolve(row[colName])
      })
      .catch((e) => {
        reject(e)
      })
  })
}
