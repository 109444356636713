import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextareaAutosize,
  Typography,
} from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react"
import APIManager from "../../manager/api"
import parse, { domToReact } from "html-react-parser"

const TermsOfServiceView = (props) => {
  const { getAccessTokenSilently } = useAuth0()
  const [policyHtml, setPolicyHtml] = useState("")

  useEffect(() => {
    fetch(`/policy/v${process.env.REACT_APP_POLICY_VERSION}.html`)
      .then((res) => res.text())
      .then((html) => setPolicyHtml(html))
  }, [])

  const [isAgreed, setIsAgreed] = useState(false)

  const agreed = async () => {
    APIManager.policyAgreed(process.env.REACT_APP_POLICY_VERSION)
      .then(() => {
        props.onAgree()
        props.onClose()
      })
      .catch((e) => {
        props.onClose(e.message)
      })
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        margin: "0",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 5000,
        backgroundColor: "#0000006e",
        ...props.sx,
      }}
    >
      <Box
        style={{
          width: "50%",
          height: "50%",
          backgroundColor: "white",
          border: "1px solid #000",
          borderRadius: "8px",
          padding: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" style={{ textAlign: "center" }}>
          利用規約
        </Typography>
        <Box
          style={{
            flexGrow: 1,
            overflowY: "scroll",
          }}
        >
          {parse(policyHtml)}
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox onChange={(e) => setIsAgreed(e.target.checked)} />
              }
              label="同意する"
            />
          </FormGroup>
          <Button
            variant="contained"
            disabled={!isAgreed}
            onClick={() => agreed()}
          >
            確認
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

TermsOfServiceView.propTypes = {
  sx: PropTypes.object,
  onAgree: PropTypes.func,
  onClose: PropTypes.func,
}

export default TermsOfServiceView
